$(function () {

    $('.main-slider__item a').on('click', function (e) {
        var bannerCode = $(this).data('id');
        yaCounter5076967.reachGoal(bannerCode);
    })

    $(document).on("change", ".select-sort" , function (e) {
        var sort = $(".select-sort option:selected").data("sort");
        var post = {
            "is_ajax":"y",
            "sort_field": sort
        }
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: post,
            success: function(msg){
                var $review = $(msg).find('.articles-list').html();
                var $pagination = $(msg).find('.pagination').html();
                $('.pagination').html($pagination);
                $(".articles-list").html($review);
                // setTimeout(function () {
                //     $('.articles-list').each(function () {
                //         $(this).find('.articles-list__item').responsiveEqualHeightGrid();
                //     })
                // }, 200);
            }
        });
    });

    $(document).on("click", ".catalog-filter__price-sort" , function (e) {
        e.preventDefault();
        var order = 'desc';
        if ($(this).hasClass('inverse')) {
            order = 'asc';
        }
        $(this).toggleClass('inverse');
        $.ajax({
            type: "GET",
            url: window.location.href+'?order='+order,
            success: function(data){
                $(".section_list").html(data);
                $('.catalog').each(function () {
                    // $(this).find('.catalog__item').responsiveEqualHeightGrid();
                    // $(this).find('.catalog__item .item__props').responsiveEqualHeightGrid();
                    // setTimeout(function () {
                        // $('.vilage-structure.vilage-structure--faq .vilage-structure__item').responsiveEqualHeightGrid();
                    // }, 200);
                });

            }
        });
    });

    $(document).on('submit', '.catalog-filter__search form',function(){
        var $form = $(this);
        $.ajax({
            type: "POST",
            url: window.location.href,
            data: $form.serialize(),
            success: function(data){
                $(".section_list").html(data);
                $('.catalog').each(function () {
                    // $(this).find('.catalog__item').responsiveEqualHeightGrid();
                    // $(this).find('.catalog__item .item__props').responsiveEqualHeightGrid();
                    // setTimeout(function () {
                    //     $('.vilage-structure.vilage-structure--faq .vilage-structure__item').responsiveEqualHeightGrid();
                    // }, 200);
                });
                // $('.section_list').html($(data).html());
            }
        });
        return false;
    })

	$(document).ready(function() {
		$('.articles-list').each(function () {
                        // $(this).find('.articles-list__item').responsiveEqualHeightGrid();
        })
	});

})